import React from "react"
import PagesLayout from "../components/layout/pages"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"

const getQuery = () => {
  if(typeof window !== "undefined"){
    const searchParams = new URLSearchParams(window?.location?.search);
    return searchParams.get('query');
  }

  return ""
}

const Search = () => {
  const { localSearchPosts: { index, store } } = useStaticQuery(graphql`
    query {
       localSearchPosts{
         index
         store
       }
    }
  `)

  const results = useFlexSearch(getQuery(), index, store)

  return (
    <PagesLayout whiteBackground className='md:pt-0' title={`Search results for ${getQuery()}`}>
      <p className='text-base mt-0 mb-[6px] text-dd-black-70'>
        Search results for:
      </p>
      <h3 className='text-4xl m-0 mb-6'>
        {getQuery()}
      </h3>
      <div className='grid gap-y-6'>
        {
          results.map(result => (
            <div className='py-8 px-[60px] bg-dd-black-10'>
              <Link to={result.uri}>
                <h5 className='m-0 font-2xl mb-4 font-medium text-dd-black-70'>
                  {result.title}
                </h5>
              </Link>
              <p className='text-xs text-dd-black-70 mt-0 whitespace-pre'>
                {result.date}   |   {result.author || "DearDesigner"}
              </p>
            </div>
          ))
        }
        {
          results.length === 0 && <h3 className='text-center font-normal'>No results found</h3>
        }
      </div>
    </PagesLayout>
  )
}

export default Search